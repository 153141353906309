import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: {
    width: 30,
    margin: 0,
    height: 30,
    padding: 0,
    fontSize: 20,
    border: 'none',
    display: 'flex',
    borderRadius: 5,
    alignItems: 'center',
    textDecoration: 'none',
    justifyContent: 'center',
    color: theme.text.primary,
    transition: 'all 0.3s ease',
    backgroundColor: theme.main.primary,
    '&:hover': {
      color: theme.main.secondary,
    },
  },
}));
