import React from 'react';
import clsx from 'clsx';
import { ImageCard, IconButton } from '@ui';
import data from '@data/db.json';
import { useTranslation } from 'react-i18next';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';

import useStyles from './styles';

const Portfolio = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const items = data.portfolio.map((item) => ({
    id: item.id,
    title: t(item.title),
    src: item.image,
    description: t(item.description),
    component: 'a',
    href: item.url,
    target: '_blank',
    className: classes.cardContaner,
    leftIcon: (
      <IconButton
        icon={faLink}
        href={item.url}
        component="a"
        target="_blank"
      />
    ),
    ...(item.repo && {
      rightIcon: (
        <IconButton
          icon={faGithub}
          href={item.repo}
          component="a"
          target="_blank"
        />
      ),
    }),
  }));

  return (
    <div className="row">
      {items.map((item) => (
        <div className={clsx('col-xs-12 col-md-4', classes.row)}>
          <ImageCard
            key={item.id}
            {...item}
          />
        </div>
      ))}
    </div>
  );
};

export default Portfolio;
