import { createUseStyles } from 'react-jss';

const BORDER = 18;

export default createUseStyles((theme) => ({
  root: {
    width: '100%',
    overflow: 'hidden',
    borderRadius: '100%',
    position: 'relative',
    boxShadow: theme.shadow.image,
    backgroundColor: theme.bg.image,
    transition: 'all 0.15s linear',
    '&::after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },
  },
  img: {
    top: BORDER,
    left: BORDER,
    objectFit: 'cover',
    position: 'absolute',
    borderRadius: '100%',
    width: `calc(100% - ${BORDER * 2}px)`,
    height: `calc(100% - ${BORDER * 2}px)`,
  },
}));
