import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

const TimelineItem = (props) => {
  const {
    to,
    label,
    title,
    content,
    className,
    from: fromText,
    ...nodeProps
  } = props;

  const classes = useStyles();

  const toText = to ? ` - ${to}` : '';
  const dateContent = fromText + toText;

  return (
    <div
      className={clsx(
        classes.root,
        className,
      )}
      {...nodeProps}
    >
      <h5 className={classes.date}>{dateContent}</h5>
      <span className={classes.label}>{label}</span>
      <h4 className={classes.title}>{title}</h4>
      <p className={classes.content}>{content}</p>
    </div>
  );
};

export const timelineItemProps = {
  to: PropTypes.string,
  from: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
};

TimelineItem.defaultProps = {
  to: null,
  from: '',
  label: null,
  title: null,
  content: null,
  className: null,
};

TimelineItem.propTypes = timelineItemProps;

export default TimelineItem;
