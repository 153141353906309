import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 330px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      fontSize: 90,
      marginBottom: 20,
    },
    [theme.breakpoints.lg]: {
      minHeight: 'calc(100vh - 192px)',
    },
  },
}));
