import { useEffect } from 'react';
import { useTheme } from 'react-jss';

import useStyles from './styles';

const GlobalStyles = () => {
  useStyles();
  const theme = useTheme();

  useEffect(() => {
    document.body.style.backgroundColor = theme.main.primary;
    document.body.style.color = theme.text.primary;
  }, [theme.name]);

  return null;
};

export default GlobalStyles;
