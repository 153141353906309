import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: ({ zIndex }) => ({
    top: 0,
    zIndex,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    position: 'fixed',
    visibility: 'hidden',
    transition: 'all 0.44s ease-in-out',
    backgroundColor: theme.name === 'dark' ? theme.palette.white : theme.palette.black,
  }),
  show: ({ opacity }) => ({
    opacity,
    visibility: 'visible',
  }),
}));
