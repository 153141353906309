import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    marginTop: 50,
    '& .col-xs-12': {
      marginTop: 25,
    },
  },
});
