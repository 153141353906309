import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

const Input = (props) => {
  const {
    error,
    className,
    component: Component,
    ...inputProps
  } = props;
  const classes = useStyles();

  return (
    <div className={clsx('form-group', classes.root)}>
      <Component
        className={clsx('form-control', className)}
        {...inputProps}
      />
      {error && (
        <div className={classes.error}>{error}</div>
      )}
    </div>
  );
};

Input.defaultProps = {
  error: null,
  className: null,
  component: 'input',
};

Input.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.string,
};

export default Input;
