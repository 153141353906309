import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

const PageContainer = ({ children, className, ...nodeProps }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        className,
      )}
      {...nodeProps}
    >
      {children}
    </div>
  );
};

PageContainer.defaultProps = {
  children: null,
  className: null,
};

PageContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default PageContainer;
