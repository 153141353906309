import { createUseStyles } from 'react-jss';

export default createUseStyles({
  titleRow: {
    marginTop: 50,
    marginBottom: 30,
  },
  marginBottom: {
    marginBottom: 30,
  },
});
