import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    marginTop: 15,
  },
  pdfViewer: {
    width: '100%',
    height: 700,
    marginTop: 50,
    objectFit: 'contain',
    '@media (max-width: 768px)': {
      height: 400,
    },
  },
  button: {
    maxWidth: 300,
  },
  downloadPDF: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
