import React from 'react';
import clsx from 'clsx';
import { ImageCard, IconButton } from '@ui';
import data from '@data/db.json';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';

import useStyles from './styles';

const Blog = () => {
  const classes = useStyles();

  const items = data.blog.map((item) => ({
    id: item.id,
    title: item.title,
    src: item.image,
    description: item.description,
    component: 'button',
    leftIcon: (
      <IconButton
        icon={faLink}
        href={item.url}
        component="a"
        target="_blank"
      />
    ),
  }));

  return (
    <div className="row">
      {items.map((item) => (
        <div className={clsx('col-xs-12 col-md-6', classes.row)}>
          <ImageCard
            key={item.id}
            {...item}
          />
        </div>
      ))}
    </div>
  );
};

export default Blog;
