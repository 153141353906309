import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './styles';

const Footer = (props) => {
  const {
    items,
    className,
    ...footerProps
  } = props;
  const classes = useStyles();

  return (
    <footer
      className={clsx(
        classes.root,
        className,
      )}
      {...footerProps}
    >
      {items.map((item) => {
        const {
          id,
          icon,
          className: itemClassName,
          component: Component = 'a',
          ...restProps
        } = item;

        return (
          <Component
            key={id}
            className={clsx(
              itemClassName,
              classes.item,
            )}
            {...restProps}
          >
            <FontAwesomeIcon
              icon={icon}
            />
          </Component>
        );
      })}
    </footer>
  );
};

Footer.defaultProps = {
  items: [],
  className: null,
};

/* eslint-disable react/forbid-prop-types */
Footer.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.any.isRequired,
    id: PropTypes.string.isRequired,
  })),
};

export default Footer;
