import React from 'react';
import clsx from 'clsx';
import { TimelineList, SectionTitle } from '@ui';
import { useTranslation, Trans } from 'react-i18next';

import useStyles from './styles';

const Resume = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const education = Object.entries(t('resume.education.items', { returnObjects: true }))
    .map(([key, value]) => ({
      id: key,
      from: value.from,
      label: value.entity,
      title: value.title,
      content: value.detail,
    }));

  const experience = Object.entries(t('resume.experience.items', { returnObjects: true }))
    .map(([key, value]) => ({
      id: key,
      from: value.from,
      to: value.to,
      label: value.company,
      title: value.title,
      content: value.detail,
    }));

  return (
    <div className="row">
      <div className={clsx('col-xs-12 col-md-6', classes.row)}>
        <SectionTitle className={classes.sectionTitle}>
          <Trans i18nKey="resume.experience.title" />
        </SectionTitle>
        <TimelineList items={experience} />
      </div>
      <div className={clsx('col-xs-12 col-md-6', classes.row)}>
        <SectionTitle className={classes.sectionTitle}>
          <Trans i18nKey="resume.education.title" />
        </SectionTitle>
        <TimelineList items={education} />
      </div>
    </div>
  );
};

export default Resume;
