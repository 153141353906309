import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

const ImageCard = (props) => {
  const {
    src,
    title,
    leftIcon,
    rightIcon,
    className,
    description,
    component: Component,
    ...nodeProps
  } = props;

  const classes = useStyles();

  return (
    <Component
      className={clsx(
        classes.root,
        className,
      )}
      {...nodeProps}
    >
      <img src={src} alt="project" />
      <div id="cover">
        {description}
      </div>
      <span className={classes.title}>{title}</span>
      {leftIcon && (
        <div className={classes.leftIcon} name="icon">
          {leftIcon}
        </div>
      )}
      {rightIcon && (
        <div className={classes.rightIcon} name="icon">
          {rightIcon}
        </div>
      )}
    </Component>
  );
};

ImageCard.defaultProps = {
  leftIcon: null,
  rightIcon: null,
  className: null,
  description: null,
  component: 'figure',
};

ImageCard.propTypes = {
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.string,
  description: PropTypes.node,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ImageCard;
