import React from 'react';
import PropTypes from 'prop-types';

const GoogleMap = (props) => {
  const {
    zoom,
    query,
    title,
    width,
    height,
    ...iframeProps
  } = props;

  return (
    <iframe
      title={title}
      width={width}
      height={height}
      scrolling="no"
      frameBorder="0"
      marginWidth="0"
      marginHeight="0"
      src={`https://maps.google.com/maps?q=${encodeURI(query)}&t=&z=${zoom}&ie=UTF8&iwloc=&output=embed`}
      {...iframeProps}
    />
  );
};

GoogleMap.defaultProps = {
  zoom: 13,
  width: '100%',
  height: '250px',
};

GoogleMap.propTypes = {
  zoom: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
};

export default GoogleMap;
