import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

const ProgressBar = (props) => {
  const {
    title,
    className,
    percentage,
    ...nodeProps
  } = props;

  const classes = useStyles({ percentage });

  return (
    <div
      className={clsx(
        classes.root,
        className,
      )}
      {...nodeProps}
    >
      {title && (
        <div className={classes.header}>
          <h4 className={classes.title}>{title}</h4>
          <span className={classes.label}>{`${percentage}%`}</span>
        </div>
      )}
      <div className={classes.progressContainer}>
        <div className={classes.progress} />
      </div>
    </div>
  );
};

ProgressBar.defaultProps = {
  title: null,
  className: null,
};

ProgressBar.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  percentage: PropTypes.number.isRequired,
};

export default ProgressBar;
