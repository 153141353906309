import { createUseStyles } from 'react-jss';

export default createUseStyles({
  navbar: ({ theme }) => ({
    marginLeft: -20,
    marginRight: -20,
    '&': {
      [theme.breakpoints.lg]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  }),
});
