import 'moment/locale/es';

import React, { useState } from 'react';
import moment from 'moment';
import data from '@data/db.json';
import { ThemeProvider } from 'react-jss';
import { useTranslation } from 'react-i18next';
import {
  Navbar,
  Footer,
  useMenuItems,
  PageContainer,
  useFooterItems,
} from '@components';

import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import {
  Title,
  Overlay,
  useTheme,
  GlobalStyles,
} from '@ui';

import useStyles from './styles';
import {
  Blog,
  Home,
  Resume,
  Contact,
  Services,
  Portfolio,
} from './sections';

function App() {
  const { t, i18n } = useTranslation();
  const { theme, toggleTheme } = useTheme();
  const classes = useStyles({ theme });

  const [showMenu, setShowMenu] = useState(false);

  const closeMenu = () => setShowMenu(false);
  const toggleMenu = () => setShowMenu((currentState) => !currentState);

  const footerItems = useFooterItems();
  const menuItems = useMenuItems(closeMenu);

  const lng = /^en/i.test(i18n.language) ? 'es' : 'en';

  moment.locale(lng);

  const toggleLang = () => i18n.changeLanguage(lng);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <PageContainer>

        <Overlay show={showMenu} onClick={toggleMenu} />
        <Navbar
          lng={lng}
          showMenu={showMenu}
          menuItems={menuItems}
          toggleLang={toggleLang}
          name={data.profile.name}
          className={classes.navbar}
          onMenuIconClick={toggleMenu}
          initial={data.profile.initial}
          lastname={data.profile.lastname}
          toggleTheme={() => toggleTheme()}
        />
        <Switch>
          <Route path="/" exact>
            <div className="container">
              <Home />
              <Services />
              <Contact />
            </div>
          </Route>
          <Route path="/resume" exact>
            <Title content={t('resume.title')} />
            <div className="container">
              <Resume />
            </div>
          </Route>
          <Route path="/portfolio" exact>
            <Title content={t('portfolio.header')} />
            <div className="container">
              <Portfolio />
            </div>
          </Route>
          <Route path="/blog" exact>
            <Title content={t('blog.header')} />
            <div className="container">
              <Blog />
            </div>
          </Route>
          <Route path="/contact" exact>
            <Title content={t('contact.header')} />
            <div className="container">
              <Contact />
            </div>
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>

      </PageContainer>
      <Footer items={footerItems} />
    </ThemeProvider>
  );
}

export default App;
