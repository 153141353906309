import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './styles';

const IconButton = (props) => {
  const {
    icon,
    className,
    component: Component,
    ...nodeProps
  } = props;

  const classes = useStyles();

  return (
    <Component
      className={clsx(
        classes.root,
        className,
      )}
      {...nodeProps}
    >
      <FontAwesomeIcon
        icon={icon}
      />
    </Component>
  );
};

IconButton.defaultProps = {
  icon: null,
  className: null,
  component: 'button',
};

/* eslint-disable react/forbid-prop-types */
IconButton.propTypes = {
  icon: PropTypes.any,
  className: PropTypes.string,
  component: PropTypes.string,
};

export default IconButton;
