import React from 'react';
import clsx from 'clsx';
import { Button } from '@ui';
import { Trans } from 'react-i18next';

import useStyles from './styles';
import { Skills, Experience, Certificates } from './components';

const Resume = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="resume">
      <Experience />
      <Certificates />
      <Skills />
      <div className="col-xs-12">
        <div className="row">
          <object
            data={`${process.env.PUBLIC_URL}/cv/CV_EN.pdf`}
            type="application/pdf"
            className={classes.pdfViewer}
          >
            <embed src={`${process.env.PUBLIC_URL}/cv/CV_EN.pdf`} type="application/pdf" />
          </object>
        </div>
        <div
          className={clsx(
            'row',
            classes.downloadPDF,
          )}
        >
          <Button
            className={classes.button}
            component="a"
            href={`${process.env.PUBLIC_URL}/cv/CV_EN.pdf`}
            target="_blank"
          >
            <Trans i18nKey="common.downloadCV" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Resume;
