import en from './en/translation.json';
import es from './es/translation.json';

export default {
  resources: {
    en: { translation: en },
    es: { translation: es },
  },
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
};
