import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

const Title = (props) => {
  const {
    content,
    className,
    ...nodeProps
  } = props;

  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        className,
      )}
      {...nodeProps}
    >
      <h1 className={classes.title}>{content}</h1>
    </div>
  );
};

Title.defaultProps = {
  content: null,
  className: null,
};

Title.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
};

export default Title;
