import { useTranslation } from 'react-i18next';
import { useLocation, matchPath } from 'react-router-dom';

const useMenuItems = (onClickHandler) => {
  const { t } = useTranslation();
  const location = useLocation();

  return [
    {
      id: 'home',
      name: t('navbar.home'),
      to: '/',
      highlight: !!matchPath(location.pathname, { path: '/', exact: true }),
      onClick: onClickHandler,
    },
    {
      id: 'resume',
      name: t('navbar.resume'),
      to: '/resume',
      highlight: !!matchPath(location.pathname, { path: '/resume', exact: true }),
      onClick: onClickHandler,
    },
    {
      id: 'portfolio',
      name: t('navbar.portfolio'),
      to: '/portfolio',
      highlight: !!matchPath(location.pathname, { path: '/portfolio', exact: true }),
      onClick: onClickHandler,
    },
    {
      id: 'blog',
      name: t('navbar.blog'),
      to: '/blog',
      highlight: !!matchPath(location.pathname, { path: '/blog', exact: true }),
      onClick: onClickHandler,
    },
    {
      id: 'contact',
      name: t('navbar.contact'),
      to: '/contact',
      highlight: !!matchPath(location.pathname, { path: '/contact', exact: true }),
      onClick: onClickHandler,
    },
  ];
};

export default useMenuItems;
