import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';

import useStyles from './styles';

const Button = (props) => {
  const {
    loading,
    variant,
    children,
    disabled,
    className,
    component: Component,
    ...buttonProps
  } = props;

  const classes = useStyles({ variant, disabled });

  return (
    <Component
      disabled={disabled || loading}
      className={clsx(
        classes.root,
        className,
      )}
      {...buttonProps}
    >
      {loading ? (
        <FontAwesomeIcon
          spin
          size="lg"
          icon={faSpinner}
        />
      ) : children}
    </Component>
  );
};

Button.defaultProps = {
  loading: false,
  children: null,
  className: null,
  disabled: false,
  variant: 'primary',
  component: 'button',
};

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default Button;
