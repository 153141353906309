import React from 'react';
import clsx from 'clsx';
import { SectionTitle, BlockInfo } from '@ui';
import { Trans, useTranslation } from 'react-i18next';

import useStyles from './styles';

const Services = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div
        className={clsx(
          'row',
          classes.titleRow,
        )}
      >
        <div className="col-xs-12 col-sm-12">
          <SectionTitle>
            <Trans i18nKey="services.title" />
          </SectionTitle>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <BlockInfo
            linecon="linecons-world"
            className={classes.marginBottom}
            title={t('services.web.title')}
            content={t('services.web.detail')}
          />
          <BlockInfo
            linecon="linecons-display"
            className={classes.marginBottom}
            title={t('services.desktop.title')}
            content={t('services.desktop.detail')}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <BlockInfo
            linecon="linecons-phone"
            className={classes.marginBottom}
            title={t('services.mobile.title')}
            content={t('services.mobile.detail')}
          />
          <BlockInfo
            linecon="linecons-lab"
            className={classes.marginBottom}
            title={t('services.architecture.title')}
            content={t('services.architecture.detail')}
          />
        </div>
      </div>
    </>
  );
};

export default Services;
