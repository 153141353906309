import data from '@data/db.json';
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons/faSoundcloud';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';

const ICONS = {
  email: faAt,
  github: faGithub,
  twitter: faTwitter,
  whatsapp: faWhatsapp,
  linkedin: faLinkedin,
  instagram: faInstagram,
  soundcloud: faSoundcloud,
  facebook: faFacebookSquare,
};

const useFooterItems = () => data.social_networks.map((item) => ({
  id: item.id,
  href: item.url,
  component: 'a',
  target: '_blank',
  icon: ICONS[item.id],
}));

export default useFooterItems;
