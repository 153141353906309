import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: {
    display: 'flex',
  },
  iconContainer: {
    paddingRight: 11,
    '& > i': {
      position: 'relative',
      fontSize: 33,
      color: theme.main.secondary,
    },
  },
  title: {
    fontSize: 18,
    margin: '0 0 7px',
    color: theme.text.primary,
  },
  content: {
    color: theme.text.secondary,
    fontSize: 13,
    margin: 0,
    padding: 0,
  },
  container: {
    paddingLeft: 15,
    paddingRight: 10,
    textAlign: 'left',
  },
}));
