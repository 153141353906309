import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    borderTop: `2px solid ${theme.border.title}`,
    '& > *:not(:last-child)': {
      marginRight: 20,
    },
  },
  item: {
    color: theme.text.primary,
    textDecoration: 'none',
    fontSize: 30,
    opacity: 0.55,
    transition: 'all .3s ease-in-out',
    '&:hover': {
      opacity: 1,
      color: theme.text.primary,
    },
  },
}));
