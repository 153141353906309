import React from 'react';
import clsx from 'clsx';
import { Avatar, Button } from '@ui';
import { Trans } from 'react-i18next';

import useStyles from './styles';

const Home = () => {
  const classes = useStyles();

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12">
        <div className={classes.homeContent}>
          <div className={clsx(classes.row, 'row')}>
            <div
              className={clsx(
                'col-sm-12 col-md-5 col-lg-5',
                classes.imageContainer,
              )}
            >
              <Avatar
                imageSrc={`${process.env.PUBLIC_URL}/img/avatar.jpg`}
                alt="profile-avatar"
              />
            </div>
            <div
              className={clsx(
                'col-sm-12 col-md-7 col-lg-7',
                classes.textContainer,
              )}
            >
              <div className={classes.homeText}>
                <div>
                  <Trans i18nKey="profile.role" />
                </div>
                <h1>
                  <Trans i18nKey="profile.name" />
                </h1>
                <p>
                  <Trans i18nKey="home.text" />
                </p>
                <div className={classes.homeButtons}>
                  <Button
                    component="a"
                    href={`${process.env.PUBLIC_URL}/cv/CV_EN.pdf`}
                    target="_blank"
                  >
                    <Trans i18nKey="common.downloadCV" />
                  </Button>
                  <Button
                    variant="secondary"
                    component="a"
                    href="#contact"
                  >
                    <Trans i18nKey="common.contact" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
