import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: {
    display: 'block',
    minHeight: 'calc(100vh - 80px)',
    position: 'relative',
    padding: '40px 70px 120px',
    [theme.breakpoints.lg]: {
      padding: '52px 40px 60px',
    },
  },
}));
