import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: {
    position: 'relative',
    marginBottom: 16,
    '& .form-control': {
      position: 'relative',
      border: `2px solid ${theme.border.input}`,
      background: '0 0',
      borderRadius: 5,
      display: 'block',
      fontSize: 14,
      margin: 0,
      padding: '10px 25px 10px 12px',
      color: theme.text.primary,
      transition: 'all 0.3s ease-in-out',
    },
    '& .form-control:focus': {
      outline: 'none',
      boxShadow: 'none',
      borderColor: theme.main.secondary,
    },
  },
  error: {
    fontSize: 12,
    position: 'absolute',
    display: 'inline-block',
    padding: '0 5px',
    zIndex: 1,
    color: theme.palette.white,
    backgroundColor: theme.main.secondary,
    margin: '-2px 0 0 10px',
    '&:after': {
      content: '" "',
      position: 'absolute',
      left: 5,
      bottom: '100%',
      width: 0,
      height: 0,
      borderBottom: `10px solid ${theme.main.secondary}`,
      borderRight: '10px solid transparent',
    },
  },
}));
