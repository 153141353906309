export default {
  name: 'dark',
  main: {
    primary: '#222',
    secondary: '#EF5555',
  },
  text: {
    primary: '#F5F5F5',
    primaryHover: '#FFF',
    secondary: '#D5D5D5',
    secondaryHover: '#333',
    primaryLight: '#FFF',
    label: '#AAA',
    subtitle: '#777',
  },
  bg: {
    primary: '#333',
    secondary: '#D5D5D5',
    sectionTitle: '#353535',
    image: '#444',
    title: '#252525',
  },
  border: {
    primary: '#EF5555',
    secondary: '#D5D5D5',
    timeline: '#333',
    card: '#444',
    input: '#555',
    title: '#333',
  },
  palette: {
    white: '#FFF',
    gray: '#888',
    black: '#000',
  },
  shadow: {
    button: '0px 10px 10px -8px rgb(0 0 0 / 78%)',
    image: '0px 0px 25px 0px rgb(0 0 0 / 80%)',
    card: '0 12px 17px rgb(0 0 0 / 12%)',
    navbar: '0px 0px 10px 0px rgb(0 0 0 / 8%)',
    sidebar: '0px 0px 70px 0px rgb(0 0 0 / 8%)',
    menuButton: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  breakpoints: {
    lg: '@media only screen and (max-width: 992px)',
  },
};
