import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: ({ variant, disabled }) => ({
    height: 42,
    fontSize: 14,
    minWidth: 120,
    borderRadius: 30,
    padding: '0 30px',
    alignItems: 'center',
    display: 'inline-flex',
    textDecoration: 'none',
    justifyContent: 'center',
    opacity: disabled ? 0.5 : 1,
    color: theme.text.primaryLight,
    boxShadow: theme.shadow.button,
    backgroundColor: theme.bg.primary,
    transition: 'all 0.3s ease-in-out',
    cursor: disabled ? 'not-allowed' : 'pointer',
    border: `2px solid ${theme.border[variant]}`,
    '&:hover': {
      backgroundColor: variant === 'primary'
        ? theme.main.secondary
        : theme.bg.secondary,
      color: theme.text[`${variant}Hover`],
    },
  }),
}));
