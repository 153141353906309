export default {
  name: 'light',
  main: {
    primary: '#FFF',
    secondary: '#EF5555',
  },
  text: {
    primary: '#333',
    primaryHover: '#FFF',
    secondary: '#666',
    secondaryHover: '#333',
    primaryLight: '#222',
    label: '#AAA',
    subtitle: '#777',
  },
  bg: {
    primary: '#FFF',
    secondary: '#D5D5D5',
    sectionTitle: '#F5F5F5',
    image: '#FFF',
    title: '#FDFDFD',
  },
  border: {
    primary: '#EF5555',
    secondary: '#D5D5D5',
    timeline: '#F5F6F9',
    card: '#E5E5E5',
    input: '#BFBFBF',
    title: '#F2F2F2',
  },
  palette: {
    white: '#FFF',
    gray: '#888',
    black: '#000',
  },
  shadow: {
    button: '0px 10px 10px -8px rgb(0 0 0 / 22%)',
    image: '0px 0px 25px 0px rgb(0 0 0 / 10%)',
    card: '0 12px 17px rgb(0 0 0 / 12%)',
    navbar: '0px 0px 10px 0px rgb(0 0 0 / 8%)',
    sidebar: '0px 0px 70px 0px rgb(0 0 0 / 8%)',
    menuButton: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  breakpoints: {
    lg: '@media only screen and (max-width: 992px)',
  },
};
