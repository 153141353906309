import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    marginTop: 50,
  },
  mapSection: {
    marginBottom: 50,
  },
  infoCol: {
    '& > div': {
      margin: '0 10px 30px 0',
    },
  },
  form: {
    marginTop: 30,
    display: 'flex',
    '& textarea': {
      resize: 'none',
    },
    '@media (max-width: 576px)': {
      flexDirection: 'column',
    },
    '& > div': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '& > div:first-child': {
      marginRight: '3%',
      '& > div': {
        marginBottom: 18,
      },
      '@media (max-width: 576px)': {
        marginRight: 0,
      },
    },
  },
  captchaContainer: {
    marginTop: 4,
    '@media (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  ctaContainer: {
    marginTop: 18,
    '@media (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  message: {
    marginTop: 14,
    fontSize: 14,
    '@media (max-width: 768px)': {
      textAlign: 'center',
    },
  },
});
