import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    '& div:first-child': {
      paddingTop: 3,
    },
    '& div:last-child:after': {
      display: 'none',
    },
  },
});
