import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: {
    padding: '65px 70px',
    backgroundColor: theme.bg.title,
    borderTop: `2px solid ${theme.border.title}`,
    borderBottom: `2px solid ${theme.border.title}`,
    margin: '0 -70px',
    [theme.breakpoints.lg]: {
      padding: '30px 40px',
      margin: '0 -40px',
    },
  },
  title: {
    margin: 0,
    padding: 0,
    fontSize: 44,
    fontWeight: 600,
    [theme.breakpoints.lg]: {
      fontSize: 36,
    },
  },
}));
