import React from 'react';
import moment from 'moment';
import data from '@data/db.json';
import chunk from 'lodash/chunk';
import { CertificateCard, SectionTitle } from '@ui';
import { useTranslation, Trans } from 'react-i18next';

import useStyles from './styles';

/* eslint-disable react/no-array-index-key */
const Certificates = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const certificates = chunk(data.certificates, 2);

  return (
    <div className={classes.root}>
      <SectionTitle>
        <Trans i18nKey="resume.certificates.title" />
      </SectionTitle>
      {certificates.map((certsCols, index) => (
        <div className="row" key={index}>
          {certsCols.map((cert) => (
            <div className="col-xs-12 col-md-6" key={cert.id}>
              <CertificateCard
                title={cert.title}
                subtitle={t('resume.certificates.membership', { id: cert.id })}
                issued={moment(cert.issued).format('MMM YYYY')}
                company={cert.company}
                component="a"
                target="_blank"
                href={cert.url}
                logo={{
                  url: `${process.env.PUBLIC_URL}${cert.logo.url}`,
                  width: cert.logo.width,
                }}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Certificates;
