import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

const SectionTitle = (props) => {
  const {
    children,
    className,
    ...nodeProps
  } = props;

  const classes = useStyles();

  return (
    <h2
      className={clsx(
        classes.root,
        className,
      )}
      {...nodeProps}
    >
      {children}
    </h2>
  );
};

SectionTitle.defaultProps = {
  children: null,
  className: null,
};

SectionTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SectionTitle;
