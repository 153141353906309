import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

const BlockInfo = (props) => {
  const {
    title,
    content,
    linecon,
    className,
    ...nodeProps
  } = props;

  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        className,
      )}
      {...nodeProps}
    >
      <div className={classes.iconContainer}>
        <i
          className={clsx(
            'linecons',
            linecon,
          )}
        />
      </div>
      <div className={classes.container}>
        <h4 className={classes.title}>{title}</h4>
        <p className={classes.content}>{content}</p>
      </div>
    </div>
  );
};

BlockInfo.defaultProps = {
  className: null,
};

BlockInfo.propTypes = {
  className: PropTypes.string,
  linecon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default BlockInfo;
