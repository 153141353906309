import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    border: 'none',
    borderRadius: 8,
    overflow: 'hidden',
    position: 'relative',
    textDecoration: 'none',
    color: theme.text.primary,
    backgroundColor: 'transparent',
    '& > div[name="icon"]': {
      opacity: 0,
      transition: 'all 0.4s ease',
    },
    '& > #cover': {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      transition: 'all 0.4s ease',
      color: theme.palette.white,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'all 450ms ease',
    },
    '&:hover': {
      '& > img': {
        filter: 'blur(5px)',
        transform: 'scale(1.2)',
      },
      '& > #cover': {
        opacity: 1,
      },
      '& > div[name="icon"]': {
        opacity: 1,
      },
    },
  },
  title: {
    top: 15,
    left: 15,
    fontSize: 14,
    borderRadius: 5,
    padding: '4px 10px',
    position: 'absolute',
    backgroundColor: theme.main.primary,
  },

  leftIcon: {
    left: 15,
    bottom: 15,
    position: 'absolute',
  },
  rightIcon: {
    right: 15,
    bottom: 15,
    position: 'absolute',
  },
}));
