import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: {
    position: 'relative',
    padding: '25px 0 20px 40px',
    '&:before': {
      content: "''",
      position: 'absolute',
      display: 'block',
      height: '100%',
      backgroundColor: theme.border.timeline,
      width: 1,
      left: 15,
      bottom: 5,
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      display: 'block',
      height: 1,
      backgroundColor: theme.border.timeline,
      width: 'calc(100% - 15px)',
      left: 15,
      bottom: 5,
    },
  },
  date: {
    display: 'inline-block',
    position: 'relative',
    fontSize: 12,
    fontWeight: 500,
    margin: '0 0 5px -40px',
    color: theme.text.secondary,
    backgroundColor: theme.main.primary,
    padding: '0 10px',
    lineHeight: '23px',
    border: `2px solid ${theme.main.secondary}`,
    borderRadius: 30,
  },
  label: {
    color: theme.name === 'dark'
      ? theme.text.label
      : theme.text.secondary,
    display: 'inline-block',
    fontSize: 12,
    opacity: 0.7,
    marginLeft: 10,
  },
  title: {
    fontSize: 16,
    margin: '10px 0 7px',
    color: theme.text.primary,
  },
  content: {
    fontSize: 13,
    color: theme.text.secondary,
  },
}));
