import { useState } from 'react';

import dark from './dark';
import light from './light';

const THEME_KEY = 'theme';
const THEME = {
  light: 'light',
  dark: 'dark',
};

const themes = {
  [THEME.dark]: dark,
  [THEME.light]: light,
};

const defaultTheme = THEME.light;

const useThemeProvider = () => {
  let initialTheme = defaultTheme;

  try {
    const storedTheme = localStorage.getItem(THEME_KEY);

    if (storedTheme) {
      initialTheme = storedTheme;
    } else {
      localStorage.setItem(THEME_KEY, defaultTheme);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  const [theme, setTheme] = useState(initialTheme);

  const changeTheme = (_theme = THEME.light) => {
    if (!Object.values(THEME).includes(_theme)) {
      // eslint-disable-next-line no-console
      console.error(`theme ${_theme} not supported`);
      return;
    }

    setTheme(_theme);

    try {
      localStorage.setItem(THEME_KEY, _theme);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const toggleTheme = () => {
    const newTheme = theme === THEME.dark ? THEME.light : THEME.dark;

    setTheme(newTheme);

    try {
      localStorage.setItem(THEME_KEY, newTheme);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return {
    THEME,
    toggleTheme,
    theme: themes[theme],
    setTheme: changeTheme,
  };
};

export default useThemeProvider;
