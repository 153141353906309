import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

const Overlay = (props) => {
  const {
    show,
    zIndex,
    opacity,
    onClick,
  } = props;

  const classes = useStyles({ zIndex, opacity });

  return (
    <div
      {...(onClick && { onClick })}
      className={clsx(
        classes.root,
        show && classes.show,
      )}
    />
  );
};

Overlay.defaultProps = {
  zIndex: 10,
  opacity: 0.5,
  onClick: null,
};

Overlay.propTypes = {
  onClick: PropTypes.func,
  zIndex: PropTypes.number,
  opacity: PropTypes.number,
  show: PropTypes.bool.isRequired,
};

export default Overlay;
