import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  homeContent: {
    margin: '5% 10%',
    '@media only screen and (max-width: 1100px)': {
      margin: '5% 0',
    },
  },
  row: {
    display: 'flex',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  homeText: {
    paddingLeft: 25,
    '& > div': {
      color: theme.text.label,
      marginBottom: 10,
    },
    '& > h1': {
      fontSize: 48,
      lineHeight: '56px',
      marginTop: 0,
      marginBottom: 20,
    },
    '& > p': {
      color: theme.text.secondary,
      fontSize: 14,
      margin: '0 0 10px',
      wordWrap: 'break-word',
      textAlign: 'left',
      [theme.breakpoints.lg]: {
        hyphens: 'auto',
      },
    },
    '@media only screen and (max-width: 767px)': {
      paddingLeft: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > h1': {
        textAlign: 'center',
      },
      '& > p': {
        textAlign: 'justify',
      },
    },
  },
  homeButtons: {
    marginTop: 27,
    '& > *:first-child': {
      marginRight: 5,
    },
    '@media only screen and (max-width: 405px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& > *:first-child': {
        marginRight: 0,
        marginBottom: 14,
      },
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media only screen and (max-width: 767px)': {
      width: '60%',
      margin: '30px auto',
    },
    '@media only screen and (max-width: 648px)': {
      width: '70%',
    },
    '@media only screen and (max-width: 500px)': {
      width: '90%',
    },
  },
}));
