import React from 'react';
import chunk from 'lodash/chunk';
import data from '@data/db.json';
import { Trans, useTranslation } from 'react-i18next';
import { SectionTitle, ProgressBar } from '@ui';

import useStyles from './styles';

/* eslint-disable react/no-array-index-key */
const Skills = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const skills = chunk(data.skills, 2);

  return (
    <div className={classes.root}>
      <SectionTitle>
        <Trans i18nKey="resume.skills.title" />
      </SectionTitle>
      {skills.map((skillsCols, index) => (
        <div className="row" key={index}>
          {skillsCols.map((skill) => (
            <div className="col-xs-12 col-sm-6" key={skill.title}>
              <ProgressBar
                title={skill.i18nTitle ? t(skill.i18nTitle) : skill.title}
                percentage={skill.percentage}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Skills;
