import React, { useState } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import data from '@data/db.json';
import { useTheme } from 'react-jss';
import ReCaptcha from 'react-google-recaptcha';
import { useTranslation, Trans } from 'react-i18next';
import {
  Input,
  Button,
  GoogleMap,
  BlockInfo,
  SectionTitle,
} from '@ui';

import useStyles from './styles';

const EMAIL_SERVICE_ENDPOINT = process.env.REACT_APP_EMAIL_SERVICE_ENDPOINT;

/* eslint-disable react/jsx-no-bind */
const Contact = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [messageStatus, setMessageStatus] = useState('pending');
  const [form, setForm] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    token: null,
  });

  const [errors, setErrors] = useState({
    name: null,
    email: null,
    subject: null,
    message: null,
    token: null,
  });

  const setFormField = (fieldName, e) => {
    setForm((currentValue) => ({
      ...currentValue,
      [fieldName]: e.target.value,
    }));

    setErrors((currentValue) => ({
      ...currentValue,
      [fieldName]: null,
    }));
  };

  const isFormFilled = Object.values(form)
    .every((field) => typeof field === 'string' && field !== '');

  const onChangeCaptcha = (token) => {
    // eslint-disable-next-line no-console
    console.log('Captcha value:', token);
    setForm({ ...form, token });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post(EMAIL_SERVICE_ENDPOINT, form);

      setForm({
        name: '',
        email: '',
        subject: '',
        message: '',
        token: null,
      });

      setMessageStatus('success');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setMessageStatus('error');
    }
    setLoading(false);
  };

  const messageFeedback = messageStatus !== 'pending' && !loading ? (
    <div className={classes.message}>
      <Trans
        i18nKey={messageStatus === 'success' ? t('contact.success') : t('contact.error')}
      />
    </div>
  ) : null;

  return (
    <div className={classes.root} id="contact">
      <div className={clsx('row', classes.mapSection)}>
        <div className="col-xs-12">
          <GoogleMap
            query="Santiago, Chile"
            title="Santiago, Chile"
          />
        </div>
      </div>
      <div className="row">
        <div className={clsx('col-xs-12 col-md-6 col-lg-4', classes.infoCol)}>
          <BlockInfo
            linecon="linecons-phone"
            title={data.profile.phone}
            content={t('contact.phone')}
          />
          <BlockInfo
            linecon="linecons-location"
            title={data.profile.location}
            content={t('contact.location')}
          />
          <BlockInfo
            linecon="linecons-mail"
            title={data.profile.email}
            content={t('contact.email')}
          />
        </div>
        <div className="col-xs-12 col-md-6 col-lg-8">
          <SectionTitle>
            <Trans i18nKey="contact.title" />
          </SectionTitle>
          <form onSubmit={handleSubmit}>
            <div className={classes.form}>
              <div>
                <Input
                  type="text"
                  value={form.name}
                  error={errors.name}
                  onChange={setFormField.bind(this, 'name')}
                  placeholder={t('contact.form.name.placeholder')}
                />
                <Input
                  type="email"
                  value={form.email}
                  error={errors.email}
                  onChange={setFormField.bind(this, 'email')}
                  placeholder={t('contact.form.email.placeholder')}
                />
                <Input
                  type="text"
                  value={form.subject}
                  error={errors.subject}
                  onChange={setFormField.bind(this, 'subject')}
                  placeholder={t('contact.form.subject.placeholder')}
                />
              </div>
              <div>
                <Input
                  rows="7"
                  value={form.message}
                  component="textarea"
                  error={errors.message}
                  onChange={setFormField.bind(this, 'message')}
                  placeholder={t('contact.form.message.placeholder')}
                />
              </div>
            </div>
            <div className={classes.captchaContainer}>
              <ReCaptcha
                key={theme.name}
                theme={theme.name}
                onChange={onChangeCaptcha}
                sitekey={process.env.REACT_APP_RE_CAPTCHA_PUBLIC_KEY}
              />
            </div>
            <div className={classes.ctaContainer}>
              <Button
                type="submit"
                loading={loading}
                disabled={!isFormFilled}
              >
                <Trans i18nKey="contact.form.submit" />
              </Button>
            </div>
            {messageFeedback}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
