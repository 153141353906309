import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: {
    userSelect: 'none',
    position: 'relative',
    paddingBottom: 40,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.lg]: {
      backgroundColor: theme.main.primary,
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      padding: '10px 20px',
      zIndex: 10,
      boxShadow: theme.shadow.navbar,
    },
  },
  logo: {
    display: 'flex',
    textDecoration: 'none',
    color: theme.main.secondary,
    transition: 'all .3s ease-in-out',
    alignItems: 'center',
  },
  logoSymbol: {
    width: 44,
    height: 44,
    backgroundColor: theme.main.secondary,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 28,
    fontWeight: 800,
    marginRight: 8,
    color: theme.palette.white,
    [theme.breakpoints.lg]: {
      width: 32,
      height: 32,
      fontSize: 18,
    },
  },
  logoText: {
    lineHeight: '50px',
    fontSize: 20,
    fontWeight: 600,
    color: theme.text.primaryLight,
    '& > span': {
      fontWeight: 400,
    },
    [theme.breakpoints.lg]: {
      fontSize: 18,
      lineHeight: '32px',
    },
  },
  siteNav: {
    display: 'flex',
    transition: 'margin 0.44s ease-in-out',
    [theme.breakpoints.lg]: {
      backgroundColor: theme.main.primary,
      position: 'fixed',
      top: 52,
      bottom: 0,
      right: 0,
      width: '100%',
      maxWidth: 220,
      padding: '0 30px',
      boxSizing: 'border-box',
      overflowX: 'scroll',
      zIndex: 10,
      boxShadow: theme.shadow.sidebar,
    },
  },
  hideMenu: {
    [theme.breakpoints.lg]: {
      marginRight: '-100%',
    },
  },
  mainMenu: {
    position: 'relative',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    zIndex: 10,
    display: 'flex',
    '&': {
      [theme.breakpoints.lg]: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      },
    },
    '& li': {
      marginRight: 40,
      '&:last-child': {
        marginRight: 0,
      },
      [theme.breakpoints.lg]: {
        margin: 0,
      },
    },
    '& li a': {
      display: 'flex',
      color: theme.text.primary,
      textDecoration: 'none',
      lineHeight: '50px',
      fontSize: 14,
      opacity: 0.55,
      transition: 'all .3s ease-in-out',
      '&:hover': {
        opacity: 1,
      },
      [theme.breakpoints.lg]: {
        display: 'block',
      },
    },
  },
  highlight: {
    '&&&': {
      opacity: 1,
    },
  },
  menuButton: {
    display: 'none',
    visibility: 'hidden',
    fontSize: 21,
    '& > svg': {
      cursor: 'pointer',
      marginLeft: 20,
    },
    [theme.breakpoints.lg]: {
      display: 'flex',
      visibility: 'visible',
      alignItems: 'center',
    },
  },
  mainMenuTheme: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
    fontSize: 20,
    '& > svg': {
      cursor: 'pointer',
    },
    [theme.breakpoints.lg]: {
      display: 'none',
    },
  },
  lang: {
    color: theme.text.primary,
    transition: 'all .3s ease-in-out',
    lineHeight: '50px',
    fontSize: 14,
    opacity: 0.55,
    padding: '0 20px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    textDecoration: 'underline',
    '&:hover': {
      opacity: 1,
    },
  },
  langMobile: {
    lineHeight: 'normal',
    padding: 0,
  },
  closeMenuButton: {
    position: 'fixed',
    border: 0,
    margin: 0,
    padding: 0,
    top: 68,
    left: 20,
    width: 55,
    height: 55,
    backgroundColor: theme.main.primary,
    boxShadow: theme.shadow.menuButton,
    borderRadius: '50%',
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'margin 0.44s ease-in-out',
    cursor: 'pointer',
    '& svg': {
      color: theme.text.primary,
      opacity: 0.55,
      fontSize: 20,
    },
    [theme.breakpoints.lg]: {
      display: 'flex',
    },
  },
  hideCloseMenuButton: {
    marginLeft: '-100%',
  },
}));
