import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: 15,
    position: 'relative',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    marginBottom: 4,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  title: {
    margin: 0,
    padding: 0,
    fontSize: 13,
    lineHeight: '1.1em',
    color: theme.text.primary,
  },
  label: {
    margin: 0,
    padding: 0,
    fontSize: 11,
    lineHeight: '1.1em',
    color: theme.palette.gray,
  },
  progressContainer: {
    width: '100%',
    borderRadius: 8,
    overflow: 'hidden',
    border: `2px solid ${theme.border.card}`,
  },
  progress: ({ percentage }) => ({
    height: 8,
    borderRadius: 9,
    overflow: 'hidden',
    width: `${percentage}%`,
    backgroundColor: theme.main.secondary,
    border: `2px solid ${theme.main.primary}`,
  }),
}));
