import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

import useStyles from './styles';

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
const Navbar = (props) => {
  const {
    lng,
    name,
    initial,
    showMenu,
    lastname,
    menuItems,
    className,
    toggleLang,
    toggleTheme,
    onMenuIconClick,
    ...nodeProps
  } = props;

  const classes = useStyles();

  return (
    <header
      className={clsx(
        classes.root,
        className,
      )}
      {...nodeProps}
    >
      <a href="/" className={classes.logo}>
        <div className={classes.logoSymbol}>{initial}</div>
        <div className={classes.logoText}>
          {name}
          &nbsp;
          <span>{lastname}</span>
        </div>
      </a>

      <button
        type="button"
        onClick={onMenuIconClick}
        className={clsx(
          classes.closeMenuButton,
          !showMenu && classes.hideCloseMenuButton,
        )}
      >
        <FontAwesomeIcon
          icon={faTimes}
        />
      </button>

      <div
        className={clsx(
          classes.siteNav,
          !showMenu && classes.hideMenu,
        )}
      >
        <div className={classes.mainMenuTheme}>
          <div className={classes.lang} onClick={toggleLang}>
            {lng}
          </div>
          <FontAwesomeIcon
            icon={faSun}
            onClick={toggleTheme}
          />
        </div>
        <ul className={classes.mainMenu}>
          {menuItems.map(({
            id,
            highlight,
            name: linkName,
            ...linkProps
          }) => (
            <li key={id}>
              <Link
                {...linkProps}
                {...(highlight && { className: classes.highlight })}
              >
                {linkName}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className={classes.menuButton}>
        <div
          onClick={toggleLang}
          className={clsx(
            classes.lang,
            classes.langMobile,
          )}
        >
          {lng}
        </div>
        <FontAwesomeIcon
          icon={faSun}
          onClick={toggleTheme}
        />
        <FontAwesomeIcon
          icon={faBars}
          onClick={onMenuIconClick}
        />
      </div>
    </header>
  );
};

Navbar.defaultProps = {
  lng: 'en',
  menuItems: [],
  className: null,
};

Navbar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  lng: PropTypes.oneOf(['en', 'es']),
  showMenu: PropTypes.bool.isRequired,
  initial: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  toggleLang: PropTypes.func.isRequired,
  toggleTheme: PropTypes.func.isRequired,
  onMenuIconClick: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    href: PropTypes.string,
    highlight: PropTypes.bool,
    id: PropTypes.string.isRequired,
  })),
};

export default Navbar;
