import { createUseStyles } from 'react-jss';

export default createUseStyles({
  row: {
    marginTop: 50,
  },
  sectionTitle: {
    marginBottom: 30,
  },
});
