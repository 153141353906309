import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

const Avatar = (props) => {
  const {
    alt,
    imageSrc,
    className,
    ...rootProps
  } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        className,
      )}
      {...rootProps}
    >
      <img
        alt={alt}
        src={imageSrc}
        className={classes.img}
      />
    </div>
  );
};

Avatar.defaultProps = {
  className: null,
};

Avatar.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
};

export default Avatar;
