import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

const CertificateCard = (props) => {
  const {
    alt,
    logo,
    title,
    issued,
    company,
    subtitle,
    className,
    component: Component,
    ...nodeProps
  } = props;

  const classes = useStyles(logo);

  return (
    <Component
      className={clsx(
        classes.component,
        className,
      )}
      {...nodeProps}
    >
      <div className={classes.root}>
        <div className={classes.imageContainer}>
          <img src={logo.url} alt={alt} />
        </div>
        <div className={classes.container}>
          {title && (
            <h4 className={classes.title}>{title}</h4>
          )}

          {subtitle && (
            <div className={classes.subtitle}>{subtitle}</div>
          )}

          {issued && (
            <div className={classes.issued}>{issued}</div>
          )}

          {company && (
            <div className={classes.company}>{company}</div>
          )}
        </div>
      </div>
    </Component>
  );
};

CertificateCard.defaultProps = {
  alt: 'logo',
  title: null,
  issued: null,
  company: null,
  subtitle: null,
  className: null,
  component: 'div',
};

CertificateCard.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  issued: PropTypes.string,
  company: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.string,
  logo: PropTypes.shape({
    url: PropTypes.string.isRequired,
    height: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
};

export default CertificateCard;
