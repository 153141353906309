import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';
import TimelineItem, { timelineItemProps } from '../TimelineItem';

const TimelineList = (props) => {
  const {
    items,
    className,
    ...nodeProps
  } = props;

  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        className,
      )}
      {...nodeProps}
    >
      {items.map(({ id, ...itemProps }) => (
        <TimelineItem key={id} {...itemProps} />
      ))}
    </div>
  );
};

TimelineList.defaultProps = {
  className: null,
};

TimelineList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    ...timelineItemProps,
  })).isRequired,
};

export default TimelineList;
