import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  root: {
    color: theme.text.primary,
    position: 'relative',
    display: 'inline-block',
    fontSize: 21,
    paddingBottom: 7,
    '&:before': {
      display: 'block',
      position: 'absolute',
      content: "''",
      width: '100%',
      backgroundColor: theme.bg.sectionTitle,
      height: 2,
      bottom: 0,
    },
    '&:after': {
      display: 'block',
      position: 'absolute',
      content: "''",
      width: 30,
      backgroundColor: theme.main.secondary,
      height: 2,
      bottom: 0,
    },
  },
}));
