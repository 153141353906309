import { createUseStyles } from 'react-jss';

export default createUseStyles({
  '@global': {
    body: {
      transition: 'all 0.15s linear',
      fontFamily: "'Poppins', Helvetica, sans-serif",
    },
    'h1, h2, h3, h4, h5, h6': {
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: 1.2,
    },
    h1: {
      fontSize: 32,
    },
    p: {
      fontSize: 14,
      margin: '0 0 10px',
      lineHeight: '1.75em',
    },
  },
});
