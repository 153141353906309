import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  component: {
    margin: 0,
    padding: 0,
    width: '100%',
    border: 'none',
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
  root: {
    width: '100%',
    display: 'flex',
    borderRadius: 8,
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'all .2s ease-in-out',
    border: `2px solid ${theme.border.card}`,
    '&:hover': {
      boxShadow: theme.shadow.card,
      transform: 'translateY(-5px)',
    },
  },
  imageContainer: ({ width, height }) => ({
    minWidth: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.name === 'dark'
      ? theme.bg.image
      : theme.bg.sectionTitle,
    '& > img': {
      objectFit: 'cover',
      ...(width && { width }),
      ...(height && { height }),
    },
  }),
  container: {
    flex: 1,
    display: 'flex',
    padding: '20px 25px',
    flexDirection: 'column',
  },
  title: {
    fontSize: 16,
    margin: '0 0 5px',
    textAlign: 'left',
    color: theme.text.primary,
  },
  subtitle: {
    fontSize: 12.5,
    margin: '5px 0',
    color: theme.text.subtitle,
  },
  issued: {
    fontSize: 9.8,
    margin: '6.5px 0',
    color: theme.text.subtitle,
  },
  company: {
    fontSize: 14,
    margin: '4px 0',
    color: theme.main.secondary,
  },
}));
